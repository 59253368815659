import SectionAbstract from "../SectionAbstract";
import SubSectionCopy from "../SubSectionCopy/SubSectionCopy.vue";
import CopySection from "../CopySection/CopySection.vue";
import { ScrollTrackerComponentManager } from 'scroll-tracker-component-manager';

export default {
  name: 'OurServicesSection',

  extends: SectionAbstract,

  components: {
    SubSectionCopy,
    CopySection
  },

  beforeCreate() {
    if (process.browser) {
      // Here we keep track of the block components on the page
      this.blockComponents = [];
      // Create the scroll tracker manager
      this.scrollTrackerComponentManager = new ScrollTrackerComponentManager({
        element: '$el',
        methods: {
          enterView: 'enterViewport',
          leaveView: 'leaveViewport',
          beyondView: 'beyondViewport',
        },
        vars: {
          enterViewThreshold: 'treshhold',
          componentId: 'id',
        },
        config: {
          setDebugLabel:false,
          // debugBorderColor: config.debug.scrollTrackerLabel.style.color,
          resizeDebounce: 100,
        },
      });
    }
  },

  mounted() {
    // this._transitionIntl.staggerFrom(this.$refs.title, 1, {opacity: 0, ease: Expo.easeOut}, 0);
    // this._transitionIntl.from(this.$refs.headline, 1, {opacity: 0}, 0);
    // this._transitionIntl.staggerFrom(this.$refs.headline.querySelectorAll(':not(strong)'), 1.5, {opacity: 0, delay: .8}, 0.3, 0);
    // this._transitionIntl.staggerFrom(this.$refs.headline.querySelectorAll('strong'), 1.5, {opacity: 0, delay: .8}, 0.2, 0);
  },

  methods: {
    handleOnReady(component) {
      // Register the new block
      this.blockComponents.push(component);
      clearTimeout(this._registerBlockTimeout);
      this._registerBlockTimeout = setTimeout(() => {
        // When all components are ready we start adding the blocks to the scroll tracker
          this.scrollTrackerComponentManager.addComponentsToScrollTrackers(this.blockComponents);
          this.scrollTrackerComponentManager.handleResize();
      }, 1000)
      }
    }
};
