//
// General GL functions
//
var gl;
var canvasWidth = 0;
var canvasHeight = 0;

var drawUi = false;
import {isPowerOf2} from './utils';


export default class GLW {
	constructor() {
		this.uniformLocations = {};
		this.attributeLocations = {};
		this.latestFreeTextureSlot = 0;
		this.assignedTextures = new Map();
		this.dataBuffers = new Map();
		this.indexBuffers = new Map();

		this.texturesConstants = [
			WebGLRenderingContext.TEXTURE0,
			WebGLRenderingContext.TEXTURE1,
			WebGLRenderingContext.TEXTURE2,
			WebGLRenderingContext.TEXTURE3,
			WebGLRenderingContext.TEXTURE4,
			WebGLRenderingContext.TEXTURE5,
			WebGLRenderingContext.TEXTURE6,
			WebGLRenderingContext.TEXTURE7,
			WebGLRenderingContext.TEXTURE8,
			WebGLRenderingContext.TEXTURE9,
			WebGLRenderingContext.TEXTURE10,
			WebGLRenderingContext.TEXTURE11,
			WebGLRenderingContext.TEXTURE12,
			WebGLRenderingContext.TEXTURE13,
			WebGLRenderingContext.TEXTURE14,
			WebGLRenderingContext.TEXTURE15,
			WebGLRenderingContext.TEXTURE16,
			WebGLRenderingContext.TEXTURE17,
			WebGLRenderingContext.TEXTURE18,
			WebGLRenderingContext.TEXTURE19,
			WebGLRenderingContext.TEXTURE20,
			WebGLRenderingContext.TEXTURE21,
			WebGLRenderingContext.TEXTURE22,
			WebGLRenderingContext.TEXTURE23,
			WebGLRenderingContext.TEXTURE24,
			WebGLRenderingContext.TEXTURE25,
			WebGLRenderingContext.TEXTURE26,
			WebGLRenderingContext.TEXTURE27,
			WebGLRenderingContext.TEXTURE28,
			WebGLRenderingContext.TEXTURE29,
			WebGLRenderingContext.TEXTURE30,
			WebGLRenderingContext.TEXTURE31
		]
		this._raf = this.raf.bind(this);
		
	}

	initGL(canvasId) {
		this.canvas = document.getElementById(canvasId);
		this.canvasWidth = this.canvas.clientWidth;
		this.canvasHeight = this.canvas.clientHeight;
		this.canvas.width = canvasWidth;
		this.canvas.height = canvasHeight;

		this.addEventListeners();
		this.gl = null;
		try { this.gl = this.canvas.getContext("experimental-webgl"); this.onResize();	}
		catch(e) {}

		return this.gl != null;
	}

	addEventListeners() {
		window.onresize = (e) =>  {
			this.onResize();
		}
	}

	removeEventListeners() {
		window.onresize = null;
	}

	onResize() {
		this.canvasWidth = this.canvas.clientWidth;
			this.canvasHeight = this.canvas.clientHeight;
			this.canvas.width = this.canvasWidth;
			this.canvas.height = this.canvasHeight;
			this.gl.viewport(0, 0, this.canvasWidth, this.canvasHeight);
	}

	textureConstant = (index) => {
		return this.texturesConstants[index];
	}

	
	startDrawing(drawCallback) {
		this.drawCallback = drawCallback;
		window.cancelAnimationFrame(this._raf);
		this._redraw(0);
	}
	
	_redraw(time) {
		for (var i = 0; i < 1; i++) { // for performance testing, increase number of draws per frame
			this.drawCallback(this.canvasWidth, this.canvasHeight, time / 1000.);
		}
		window.requestAnimationFrame(this._raf);
	}

	raf(time) {
		this._redraw(time, this.drawCallback)
	}
	
	setNormalDepthTestAndBlending() {
		this.gl.clearColor(0.0, 0.0, 0.0, 1.0);  // Clear to black, fully opaque
		this.gl.clearDepth(1.0);                 // Clear everything
		this.gl.enable(this.gl.DEPTH_TEST);           // Enable depth testing
		this.gl.depthFunc(this.gl.LEQUAL);            // Near things obscure far things
		this.gl.enable(this.gl.BLEND);                                    // Enable blending
		this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);     // Normal blend equation
	}
	
	clear() {
		this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
	}
	
	//
	// Shader functions
	//
	
	shaderFromSource(vsSource, fsSource) {
		const vsShader = this.gl.createShader(this.gl.VERTEX_SHADER);
		const fsShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
	
		this.gl.shaderSource(vsShader, vsSource);
		this.gl.compileShader(vsShader);
	
		if (!this.gl.getShaderParameter(vsShader, this.gl.COMPILE_STATUS)) {
			console.log("Error compiling vertex shader: " + this.gl.getShaderInfoLog(vsShader));
			return null;
		}
	
		this.gl.shaderSource(fsShader, fsSource);
		this.gl.compileShader(fsShader);
	
		if (!this.gl.getShaderParameter(fsShader, this.gl.COMPILE_STATUS)) {
			console.log("Error compiling fragment shader: " + this.gl.getShaderInfoLog(fsShader));
			return null;
		}
	
		const program = this.gl.createProgram();
		this.gl.attachShader(program, vsShader);
		this.gl.attachShader(program, fsShader);
		this.gl.linkProgram(program);
	
		if (!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
			console.error("Unable to initialize the shader program.");
			return null;
		}
		else {
			return program;
		}
	}
	
	setAttribute(program, name, data, numPerElement) {
		var attributeLocation = this.getAttributeLocation(program, name);
		var buffer = this._getVertexDataBuffer(data);
	
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
		this.gl.vertexAttribPointer(attributeLocation, numPerElement, this.gl.FLOAT, false, 0, 0);
	}
	
	setUniformFloat(program, name, value) {
		var uniformLocation = this.getUniformLocation(program, name);
		this.gl.useProgram(program);
		this.gl.uniform1f(uniformLocation, value);
	}
	
	setUniformVector2(program, name, value) {
		var uniformLocation = this.getUniformLocation(program, name);
		this.gl.useProgram(program);
		this.gl.uniform2f(uniformLocation, value[0], value[1]);
	}
	
	setUniformTexture(program, name, texture) {
		var uniformLocation = this.getUniformLocation(program, name);
		var textureSlot = this.getTextureSlot(texture);
	
		this.gl.activeTexture(this.textureConstant(textureSlot));
	
		this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
		this.gl.useProgram(program);
		this.gl.uniform1i(uniformLocation, textureSlot);
	}
	
	
	getUniformLocation(program, name) {
		if (typeof this.uniformLocations[name] === "undefined") {
			this.uniformLocations[name] = this.gl.getUniformLocation(program, name);
		}
		return this.uniformLocations[name];
	}
	
	
	getAttributeLocation(program, name) {
		if (typeof this.attributeLocations[name] === "undefined") {
			this.attributeLocations[name] = this.gl.getAttribLocation(program, name);
			this.gl.enableVertexAttribArray(this.attributeLocations[name])
		}
	
		return this.attributeLocations[name];
	}
	
	
	getTextureSlot(texture) {
		if (typeof this.assignedTextures.get(texture) === "undefined") {
			this.assignedTextures.set(texture, this.latestFreeTextureSlot);
			this.latestFreeTextureSlot++;
		}
		return this.assignedTextures.get(texture);
	}
	
	//
	// drawing
	//
	
	drawIndexedVerticesWithShader(positions, indices, program) {
		this.gl.useProgram(program);
	
		var positionsBuffer = this._getVertexDataBuffer(positions);
		var indexBuffer = this._getIndexBuffer(indices);
	
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionsBuffer);
		this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
		this.gl.drawElements(this.gl.TRIANGLES, indices.length, this.gl.UNSIGNED_SHORT, 0);
	}
	
	//
	// Buffer utilities
	//
	
	_getVertexDataBuffer(data) {
		if (typeof this.dataBuffers.get(data) === "undefined") {
			var buffer = this._vertexDataBufferFromArray(data);
			this.dataBuffers.set(data, buffer);
		}
		return this.dataBuffers.get(data);
	}
	
	_getIndexBuffer(data) {
		if (typeof this.indexBuffers.get(data) === "undefined") {
			var buffer = this._indexBufferFromArray(data);
			this.indexBuffers.set(data, buffer);
		}
		return this.indexBuffers.get(data);
	}
	
	_vertexDataBufferFromArray(data) {
		var buffer = this.gl.createBuffer();
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(data), this.gl.STATIC_DRAW);
		return buffer;
	}
	
	_indexBufferFromArray(data) {
		var buffer = this.gl.createBuffer();
		this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, buffer);
		this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(data), this.gl.STATIC_DRAW);
		return buffer;
	}
	
	//
	// Texture functions
	//
	
	loadTexture(path, wrap, callback) {
		this._loadTexture(path, 0, wrap, function (doneIndex, texture) {
			if (texture == null) {
				console.error("Error loading texture " + path);
				return;
			}
	
			callback.call( this, texture );
		});
	}
	
	loadTextures(paths, wrap, callback) {
		var numToDo = paths.length;
		var textures = [];
	
		for (var index = 0; index < paths.length; ++index) {
			this._loadTexture(paths[index], index, wrap, function (doneIndex, texture) {
				textures[doneIndex] = texture;
	
				numToDo--;
				if (numToDo === 0) callback.call( this, textures );
			});
		}
	}
	
	_loadTexture(path, index, wrap, callback) {
		var texture = this.gl.createTexture();
		this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
		this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([255, 0, 0, 255])); // red
	
		var image = new Image();
		image.onload = () => {
			this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
			this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image);
			this.setupTextureFilteringAndMips(image.width, image.height, wrap);
	
			texture.width = image.width;
			texture.height = image.height;
			texture.ratio = image.width / image.height;
	
			this.gl.bindTexture(this.gl.TEXTURE_2D, null);
	
			callback(index, texture);
		}
		image.src = path;
		texture.src = image.src;
		texture.width = 0;
		texture.height = 0;
		texture.ratio = 1;
	
		return texture;
	}
	
	setupTextureFilteringAndMips(width, height, wrap) {
		if (isPowerOf2(width) && isPowerOf2(height)) {
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, wrap ? this.gl.REPEAT : this.gl.CLAMP_TO_EDGE);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, wrap ? this.gl.REPEAT : this.gl.CLAMP_TO_EDGE);
			this.gl.generateMipmap(this.gl.TEXTURE_2D);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
		} else {
			// no wrapping or mipmapping support for non-power-of-two (NPOT) textures
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
			this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
		}
	}

	destroy() {
		window.cancelAnimationFrame(this._raf);
		this.gl = null;
	}


}

