import SectionAbstract from "../SectionAbstract";

export default {
  name: 'ContactSection',

  extends: SectionAbstract,

  mounted() {
    this.$nextTick(() => {
      this._transitionIntl.from(this.$refs.header, 1, {opacity: 0, ease: Expo.easeOut}, 0);
      this._transitionIntl.staggerFrom(this.$refs.contactOptions.querySelectorAll('li'), 1, {opacity: 0, ease: Expo.easeOut, delay: .2}, 0.1, 0);
      this._transitionIntl.staggerFrom(this.$refs.contactOptions.querySelectorAll('li > a'), 1, {opacity: 0, top: 20, ease: Expo.easeOut, delay: .4}, 0.1, 0);
    })
  }
};
