export const loadMultipleTexts = function (paths, callback) {
	if (paths.length === 0) callback([]);
	var numLeft = paths.length;
	var texts = [];

	for (var index = 0; index < paths.length; ++index) {
		loadText(paths[index], index, function (doneIndex, text) {
			texts[doneIndex] = text;
			numLeft--;
			if (numLeft === 0) callback.call( this, texts );
		});
	}
}

export const loadText = function(path, i, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onreadystatechange = function (e) {
		if (xhr.readyState === 4) {
			callback(i, xhr.responseText);
		}
	};
	xhr.open('GET', path, true);
	xhr.send();
}

export const isPowerOf2 = function(value) {
	return (value & (value - 1)) == 0;
}

export const getTexcoordScaleToFill = function(screenRatio, textureRatio) {
	var result = [];
	if (textureRatio > screenRatio) {
		result[0] = screenRatio / textureRatio;
		result[1] = 1;
	}
	else {
		result[0] = 1;
		result[1] = textureRatio / screenRatio;
	}
	return result;
}