import ProductSection from '../ProductSection/ProductSection.vue';
import SectionAbstract from '../SectionAbstract';
import VueTypes from 'vue-types';
import { ScrollTrackerComponentManager } from 'scroll-tracker-component-manager';
import { mapMutations } from 'vuex';
import { ControlNamespace, ControlMutationTypes } from '../../../store/module/control';


export default {
  name: 'ProductListSection',

  extends: SectionAbstract,
  
  props: {
    data: VueTypes.object.isRequired,
  },

  components: {
    ProductSection,
  },

  beforeCreate() {
    if (process.browser) {
      // Here we keep track of the block components on the page
      this.blockComponents = [];
      // Create the scroll tracker manager
      this.scrollTrackerComponentManager = new ScrollTrackerComponentManager({
        element: '$el',
        methods: {
          enterView: 'enterViewport',
          leaveView: 'leaveViewport',
          beyondView: 'beyondViewport',
        },
        vars: {
          enterViewThreshold: 'treshhold',
          componentId: 'id',
        },
        config: {
          setDebugLabel:false,
          // debugBorderColor: config.debug.scrollTrackerLabel.style.color,
          resizeDebounce: 100,
        },
      });
    }
  },

  methods: {
    ...mapMutations({
      setShowMenu: ControlMutationTypes.SHOW_MENU,
    }),

    handleOnReady(component) {
      // Register the new block
      this.blockComponents.push(component);
      clearTimeout(this._registerBlockTimeout);
        this._registerBlockTimeout = setTimeout(() => {
          // When all components are ready we start adding the blocks to the scroll tracker
            this.scrollTrackerComponentManager.addComponentsToScrollTrackers(this.blockComponents);
        }, 3000)
      }
    }
};
