import { TweenMax, TimelineLite, Expo } from "gsap";
import VueTypes from 'vue-types';

import shuffleArray from "../../../util/shuffleArray";
import { TweenLite } from "../../../node_modules/gsap/TweenLite";
import SectionAbstract from "../SectionAbstract";
import GLW from "../../../util/gl-utils/glUtils";
import { loadMultipleTexts, getTexcoordScaleToFill } from "../../../util/gl-utils/utils";

if (process.browser) {
  require('~/vendor/gsap/plugins/SplitText');
}

export default {
  name: 'LandingSection',

  extends: SectionAbstract,

  props: {
    data: VueTypes.object,
  },

  mounted() {
    this.$nextTick(() => {
      this.quadPositions = [
        -1, 1,	// top left
        1, 1,	// top right
        -1, -1,	// bottom left
        1, -1	// bottom right
      ];
      this.quadUVs = [
        0, 0,
        1, 0,
        0, 1,
        1, 1,
      ]
      this.quadIndices = [
        0, 1, 2,
        1, 3, 2,
      ]
  
      if (process.browser) {
        this.init();
      }
    });
  },

  methods: {
    init() {
      this.glw = new GLW();
      if (this.glw.initGL("glcanvas")) {
        this.glw.setNormalDepthTestAndBlending();
        this.setup();
      }
      else {
        console.log("Error: GL not initialized, use fallback");
      }
    },

  setup() {
    loadMultipleTexts(["shader/vert.glsl", "shader/frag.glsl"], (texts) => {
      this.shaderProgram = this.glw.shaderFromSource(texts[0], texts[1]);

      this.glw.loadTextures(["logo.png", "background.jpg"], true,  (textures) => {
        this.logoTexture = textures[0];
        this.backgroundTexture = textures[1];

        this.onLoadDone();
      });
    });
  },

	onLoadDone() {
		this.glw.setAttribute(this.shaderProgram, "aVertexPosition", this.quadPositions, 2);
		this.glw.setAttribute(this.shaderProgram, "aTextureCoord", this.quadUVs, 2);
		this.glw.setUniformTexture(this.shaderProgram, "uLogo", this.logoTexture);
		this.glw.setUniformTexture(this.shaderProgram, "uBackground", this.backgroundTexture);

		this.glw.startDrawing(this.draw);
	},


  draw(width, height, time) {
    this.glw.clear();
    var screenRatio = width / height;

    this.glw.setUniformVector2(this.shaderProgram, "uLogoCoordScale", [screenRatio / this.logoTexture.ratio, 1]); // always fit height to view
    this.glw.setUniformVector2(this.shaderProgram, "uBackgroundCoordScale", getTexcoordScaleToFill(screenRatio, this.backgroundTexture.ratio)); // always fill screen
    this.glw.setUniformFloat(this.shaderProgram, "uOffset", time * 0.025);
    this.glw.drawIndexedVerticesWithShader(this.quadPositions, this.quadIndices, this.shaderProgram);
  }
  },

  beforeDestroy() {
    if(this.glw) {
      this.glw.destroy();
    }
  }
};
