import SectionAbstract from "../SectionAbstract";
import VueTypes from 'vue-types';
import nullableVueType from "../../../util/nullableVueType";

export default {
  name: 'SubSectionCopy',

  extends: SectionAbstract,

  props: {
    data: VueTypes.objectOf({
      separator: nullableVueType(VueTypes.string).isRequired,
      backgroundColor: nullableVueType(VueTypes.string).isRequired,
      fontColor: nullableVueType(VueTypes.string).isRequired,
      subHeader: nullableVueType(VueTypes.string).isRequired,
      description: VueTypes.string.isRequired,
      columnLayout: nullableVueType(VueTypes.bool),
    }),
    reverse: VueTypes.number,
    id: VueTypes.string
   
  },

  data() {
    return {
      isVideoPlaying: false,
      treshhold: .3,
    }
  },

  mounted() {
    this.top = this.$el.getBoundingClientRect().top;
   setTimeout(() => {
      // console.log(this.$refs.copy, this.$refs.subheader)
      // const description = new SplitText(this.$refs.copy, {type:"lines"});
      // const strongs = this.$refs.subheader.querySelector('strong');
      // this._transitionIntl.from(this.$refs.subheader, 2, {opacity:0},  0);
      // if(strongs) {
      //   this._transitionIntl.from(this.$refs.subheader.querySelector('strong'), 1, {opacity:0, delay: 0.1}, 0);
      // }
      // this._transitionIntl.staggerFrom(description.lines, 1, {opacity:0, y: 80, ease: Expo.easeOut, delay: .2, clearProps: 'all'}, 0.1, 0);
      if(this.$refs.imageSeparator) {
        this.loop();
        this._transitionIntl.fromTo(this.$refs.imageSeparator, 1, {opacity:0,  paddingTop: 0}, {opacity:1, paddingTop: "25%", ease: Expo.easeOut, delay: .2}, 0);
      }
    }, 1000)
  },

  methods: {
    ready (event) {
      this._player = event;
    },

    playing (event) {
      // The player is playing a video.
      this.isVideoPlaying = true;
    },

    ended(event) {
      this.isVideoPlaying = false;
    },

    onVideoClick() {
      this._player.playVideo();
    },

    loop(){
      requestAnimationFrame(this.loop);
      if(this.$refs.imageSeparator) {
        const factor = document.documentElement.scrollTop/this.top;
        TweenLite.set(this.$refs.imageSeparator, {yPercent: -25 * factor})
        TweenLite.set(this.$refs.sectionCopy, {y: 20 * factor})
      }
      // const {top} = this.$el.getBoundingClientRect();
    
    },
  },

};
