import SectionAbstract from "../SectionAbstract";
import { TweenLite, Linear } from "gsap/TweenMax";
import VueTypes from 'vue-types';

export default {
  name: 'ProductSection',

  extends: SectionAbstract,

  props: {
    data: VueTypes.object.isRequired,
  },

  data() {
    return {
      treshhold: .0,
      transitionIn: false,
    }
  },

  mounted() {
    // this.loop();
    setTimeout(() => {
      // this.top = this.$el.getBoundingClientRect().top;
      // this._transitionIntl.eventCallback('onComplete', this.handleTransitionComplete)
      // this._transitionIntl.from(this.$refs.image, .75, {height: 0, top: 40, ease: Expo.easeOut}, 0);
      // this._transitionIntl.from(this.$refs.title, .5, {opacity: 0, top: 20, ease: Expo.easeOut}, 0);
      // this._transitionIntl.from(this.$refs.intro, 1, {opacity: 0, top: 20, ease: Expo.easeOut, delay: .4}, 0);
      // this._transitionIntl.from(this.$refs.seeMoreCta, 1, {opacity: 0, scale: 1.2, ease: Expo.easeOut, delay: .4}, 0);
      // this._transitionIntl.staggerFrom(this.$refs.intro.querySelectorAll('strong'), 1, {opacity: 0, ease: Expo.easeOut, delay: .4}, 0.1, 0);
    }, 500)
  },

  methods: {
    loop(){
      requestAnimationFrame(this.loop);
      // const {top} = this.$el.getBoundingClientRect();
      const factor = document.documentElement.scrollTop/this.top;
      TweenLite.set(this.$refs.product, {top: 100 * factor/2})
      TweenLite.set(this.$refs.number, {top: -100 * factor/2})
    },
    handleTransitionComplete() {
      this.transitionIn = true;
    },
    pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    onClick() {
      window.open(this.data.url);
    }
  }
};
